import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import notesApi, {TGetNotesParams, TCreateOrEditNoteParams} from '@/_api/notes/notes.api';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TNote} from '@/_types/store/note.type';
import {TStoreEntityState} from '@/_types/store/store-entity-state.type';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getNotesRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getAllNotes.bind(notesApi));
const getCompanyPublicNotesRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getAllNotes.bind(notesApi));
const getNotesMyRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getMyNotes.bind(notesApi));
const addNote = new AxiosCancellableRequest<TCreateOrEditNoteParams, TNote>(notesApi.addNote.bind(notesApi));
const editNote = new AxiosCancellableRequest<TCreateOrEditNoteParams, boolean>(notesApi.editNote.bind(notesApi));
const removeNote = new AxiosCancellableRequest<TGetNotesParams, boolean>(notesApi.removeNote.bind(notesApi));
const addFav = new AxiosCancellableRequest<number, boolean>(notesApi.addFav.bind(notesApi));
const removeFav = new AxiosCancellableRequest<number, boolean>(notesApi.removeFav.bind(notesApi));

type TNotesStoreState = {
  eventId: number;
  companyExternalId: string;
  notesStoreStateEntity: TStoreEntityState<TApiListResponse<TNote>>;
  companyNotesStoreStateEntity: TStoreEntityState<TApiListResponse<TNote>>;
  notesByOwnerUserId: { [userId: number ]: TNote[] };
}

const notesStore: Module<TNotesStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    companyExternalId: null,
    notesStoreStateEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    companyNotesStoreStateEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    notesByOwnerUserId: {},
  },
  getters: {
    eventId: (state: TNotesStoreState): number => {
      return state.eventId;
    },
    isNotesStoreStateEntityLoading: (state: TNotesStoreState): boolean => {
      return state.notesStoreStateEntity.isLoading;
    },
    notesStoreStateEntity: (state: TNotesStoreState): TApiListResponse<TNote> => {
      return state.notesStoreStateEntity.data;
    },
    areCompanyNotesLoading: (state: TNotesStoreState): boolean => {
      return state.companyNotesStoreStateEntity.isLoading;
    },
    noteList: (state: TNotesStoreState): TNote[] => {
      return (state.notesStoreStateEntity.data && state.notesStoreStateEntity.data.List) || [];
    },
    companyNotes: (state: TNotesStoreState): TNote[] => {
      return (state.companyNotesStoreStateEntity.data && state.companyNotesStoreStateEntity.data.List) || [];
    },
    notesByUserId: (state: TNotesStoreState): ((userId: number) => TNote[]) => {
      return (userId: number): TNote[] => state.notesByOwnerUserId[userId] || [];
    },
  },
  actions: {

    getAllNotes: async ({ commit, state }: ActionContext<TNotesStoreState, TAppStoreState>, params: TGetNotesParams): Promise<TApiListResponse<TNote>> => {
      const {eventId} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      let data;
      const isForGivenUser = !!(params && params.queryParams && params.queryParams.user_id);

      try {
        data = await getNotesRequest.load(params);
        if (isForGivenUser) {
          commit('updateNotesByOwnerUserId', (data && data.List) || [])
        } else {
          commit('setNoteList', data);
        }
        return data;
      } catch (error) {
        commit('noteListError', error);
        return null;
      } finally {
        if (isForGivenUser) {
          commit('updateNotesByOwnerUserId', (data && data.List) || [])
        } else {
          commit('setNoteList', data);
        }
      }
    },

    getCompanyPublicNotes: async ({ commit, state }: ActionContext<TNotesStoreState, TAppStoreState>, params: TGetNotesParams): Promise<TApiListResponse<TNote>> => {
      const {eventId, queryParams} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      if (!queryParams || !queryParams.external_id) {
        return null;
      }

      const { external_id } = queryParams;

      if (state.companyExternalId !== external_id) {
        commit('setCompanyExternalId', external_id);
      }

      let data;

      try {
        data = await getCompanyPublicNotesRequest.load(params);
        commit('setCompanyNotes', data);
        return data;
      } catch (error) {
        commit('companyNoteListError', error);
        return null;
      } finally {
        commit('setCompanyNotes', data);
      }
    },

    getMyNotes: async ({ commit, state }: ActionContext<TNotesStoreState, TAppStoreState>, params: TGetNotesParams): Promise<TApiListResponse<TNote>> => {
      const {eventId} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      let data;

      try {
        data = await getNotesMyRequest.load({ eventId });
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('noteListError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },

    addNote: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params: TCreateOrEditNoteParams): Promise<TNote> => {
      try {
        return await addNote.load(params);
      } catch (error) {
        commit('noteListError', error);
        return null;
      }
    },

    editNote: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params: TCreateOrEditNoteParams): Promise<boolean> => {
      try {
        return await editNote.load(params);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

    removeNote: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, params): Promise<boolean> => {
      try {
        return await removeNote.load(params);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

    addFav: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, idNote: number): Promise<boolean> => {
      try {
        return await addFav.load(idNote);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

    removeFav: async ({ commit }: ActionContext<TNotesStoreState, TAppStoreState>, idNote: number): Promise<boolean> => {
      try {
        return await removeFav.load(idNote);
      } catch (error) {
        commit('noteListError', error);
        return false;
      }
    },

  },
  mutations: {

    setEventId(state: TNotesStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }

      state.eventId = eventId || null;
      state.notesStoreStateEntity.data = null;
      state.notesStoreStateEntity.isLoading = false;
      state.notesStoreStateEntity.error = null;
      state.notesByOwnerUserId = {};
    },

    setCompanyExternalId(state: TNotesStoreState, companyExternalId: string): void {
      if (state.companyExternalId === companyExternalId) {
        return;
      }

      state.companyExternalId = companyExternalId || null;
      state.companyNotesStoreStateEntity.data = null;
      state.companyNotesStoreStateEntity.isLoading = false;
      state.companyNotesStoreStateEntity.error = null;
    },

    setNoteList(state: TNotesStoreState, noteList: TApiListResponse<TNote>): void {
      state.notesStoreStateEntity.data = noteList || null;
      state.notesStoreStateEntity.isLoading = false;
    },

    updateNotesByOwnerUserId(state: TNotesStoreState, notes: TNote[]): void {
      (notes || []).forEach(note => {
        if (!state.notesByOwnerUserId[note.owner.user_id]) {
          state.notesByOwnerUserId[note.owner.user_id] = [];
        }
        if (!state.notesByOwnerUserId[note.owner.user_id].find(existingNote => existingNote.id === note.id)) {
          state.notesByOwnerUserId[note.owner.user_id].push(note);
        }
      });
    },

    noteListError(state: TNotesStoreState, error: ApiErrorResponseData): void {
      state.notesStoreStateEntity.error = error;
    },

    setCompanyNotes(state: TNotesStoreState, noteList: TApiListResponse<TNote>): void {
      state.companyNotesStoreStateEntity.data = noteList || null;
      state.companyNotesStoreStateEntity.isLoading = false;
    },

    companyNoteListError(state: TNotesStoreState, error: ApiErrorResponseData): void {
      state.companyNotesStoreStateEntity.error = error;
    },

  },
};

export default notesStore;
