import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TTicketsStoreState} from '@/_types/store/tickets-store-state.type';
import ticketsApi, {
  TDeleteTicketRequestParams,
  TGetSingleTicketRequestParams,
  TGetTicketsRequestParams,
  TTicket
} from '@/_api/tickets.api';

const ticketsStore: Module<TTicketsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    tickets: null,
    singleTicket: null,
    isLoading: false,
    isConfirmTicketDeletionPopupVisible: false,
  },
  getters: {
    tickets: (state: TTicketsStoreState): TTicket[] => state.tickets || [],
    singleTicket: (state: TTicketsStoreState): TTicket => {
      return state.singleTicket || null;
    },
    isLoading: (state: TTicketsStoreState): boolean => state.isLoading,
    isConfirmTicketDeletionPopupVisible: (state: TTicketsStoreState): boolean => state.isConfirmTicketDeletionPopupVisible,
  },
  mutations: {
    setEventId(state: TTicketsStoreState, eventId: number): void {
      state.eventId = eventId;
    },
    showTicketDeletionConfirmPopup(state: TTicketsStoreState): void {
      state.isConfirmTicketDeletionPopupVisible = true;
    },
    hideTicketDeletionConfirmPopup(state: TTicketsStoreState): void {
      state.isConfirmTicketDeletionPopupVisible = false;
    },
    setIsLoading(state: TTicketsStoreState, newVal: boolean): void {
      state.isLoading = newVal;
    },
    setDeletionConfirmationVisibility(state: TTicketsStoreState, newVal: boolean): void {
      state.isConfirmTicketDeletionPopupVisible = newVal;
    },
    setTickets(state: TTicketsStoreState, newVal: TTicket[]): void {
      state.tickets = newVal || [];
    },
    deleteTicket(state: TTicketsStoreState, ticketId: number): void {
      const ticketIndex = state.tickets.findIndex(ticket => ticket.id === ticketId);
      if (ticketIndex < 0) {
        return;
      }
      state.tickets.splice(ticketIndex, 1);
    },
    setSingleTicket(state: TTicketsStoreState, ticket: TTicket): void {
      state.singleTicket = ticket || null;
    },
  },
  actions: {
    requestTickets: async ({state, commit}: ActionContext<TTicketsStoreState, TAppStoreState>, params: TGetTicketsRequestParams): Promise<TTicket[]> => {
      const { eventId } = params;
      if (eventId === state.eventId && state.tickets && !state.isLoading) {
        return state.tickets;
      }

      commit('setEventId', eventId);
      commit('setIsLoading', true);

      const tickets = await ticketsApi.getTickets(params);
      if (tickets) {
        commit('setTickets', tickets);
      }

      commit('setIsLoading', false);
      return tickets;
    },
    showDeletionConfirm: ({commit}: ActionContext<TTicketsStoreState, TAppStoreState>): void => {
      commit('setDeletionConfirmationVisibility', true);
    },
    hideDeletionConfirm: ({commit}: ActionContext<TTicketsStoreState, TAppStoreState>): void => {
      commit('setDeletionConfirmationVisibility', false);
    },
    deleteTicket: async ({commit}: ActionContext<TTicketsStoreState, TAppStoreState>, params: TDeleteTicketRequestParams): Promise<void> => {
      const { ticketId } = params;
      await ticketsApi.deleteTicket(params);
      commit('deleteTicket', ticketId);
    },
    requestSingleTicket: async ({state, commit}: ActionContext<TTicketsStoreState, TAppStoreState>, params: TGetSingleTicketRequestParams): Promise<void> => {
      const preExistingTicket: TTicket = state.tickets && state.tickets.find(ticket => ticket.id === params.ticketId);
      const isSingleTicketSame = !!(state.singleTicket && (state.singleTicket.id === params.ticketId));
      if (isSingleTicketSame) {
        return;
      } else if (preExistingTicket) {
        commit('setSingleTicket', preExistingTicket);
        return;
      }

      let singleTicket: TTicket = null;

      try {
        singleTicket = await ticketsApi.getTicket(params);
      } catch {
        /* ignore */
      }

      commit('setSingleTicket', singleTicket);
    }
  },
};

export default ticketsStore;
