


import { Vue, Component } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { TLoginByOauthProviderRequestParams } from '@/_api/login/login.api';

export const GOOGLE_OAUTH_CLIENT_ID = '781650325147-tsqbo5ujif98q3di3guhe2ufkil39gii.apps.googleusercontent.com';
const GOOGLE_CLIENT_SCRIPT_ID = 'gsiClientScript';
const GOOGLE_CRED_RESPONSE_CALLBACK_NAME = 'gsiClientCredentialResponseCallback';
const FACEBOOK_SDK_SCRIPT_ID = 'facebook-jssdk';
const FACEBOOK_APP_ID = '677072007014085';

// N.B.: manage app here https://www.linkedin.com/developers/apps/206426257/auth
const LINKEDIN_OAUTH_INIT_URL = 'https://www.eventswallet.com/api/oauth-linkedin/init';

type TGoogleCredentialResponse = {
  clientId: string;
  credential: string; // JWT token
  select_by: string;
}

type TFacebookAuthResponse = {
  accessToken: string; // Access token for further requests to graph.facebook.com — it is what we need.
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string; // JWT token, but contains no name or email
  userID: string; // Facebook user id
}

type TFacebookCredentialResponse = {
  authResponse?: TFacebookAuthResponse;
  status: string;
}

type TLinkedInPostMessageData = {
  event_name: string;
  code: string;
}

@Component
export default class OauthButtons extends Vue {

  @Getter('authStore/deviceId') deviceId: string;
  @Getter('authStore/isAuthenticated') isAuthenticated: string;

  @Action('authStore/loginByGoogle') loginByGoogle: (payload: TLoginByOauthProviderRequestParams) => Promise<void>;
  @Action('authStore/loginByFacebook') loginByFacebook: (payload: TLoginByOauthProviderRequestParams) => Promise<void>;
  @Action('authStore/loginByLinkedIn') loginByLinkedIn: (payload: TLoginByOauthProviderRequestParams) => Promise<void>;

  public googleResponseHandlerName: string = GOOGLE_CRED_RESPONSE_CALLBACK_NAME;
  public googleOauthClientId: string = GOOGLE_OAUTH_CLIENT_ID;
  public isErrorVisible: boolean = false;
  public oauthProviderNameForErrorMessage: string = '';
  public linkedInAuthWindow: Window = null;

  public mounted(): void {
    this.loadGoogleClientOnce();
    this.loadFacebookSDKOnce();
  }

  public beforeDestroy(): void {
    this.removeGoogleClientScript();
    this.removeFacebookSDKScript();
    this.stopLinkedInPostmessageHandling();
    this.linkedInAuthWindow = null;
  }

  public loadGoogleClientOnce(): void {
    if (document.getElementById(GOOGLE_CLIENT_SCRIPT_ID)) {
      return;
    }
    (window as any)[GOOGLE_CRED_RESPONSE_CALLBACK_NAME] = this.gsiClientCredentialResponseCallback;

    const head = document.getElementsByTagName('head')[0];
    const gsiClient = document.createElement('script');
    gsiClient.setAttribute('id', GOOGLE_CLIENT_SCRIPT_ID);
    gsiClient.src = 'https://accounts.google.com/gsi/client';
    gsiClient.async = true;
    gsiClient.defer = true;
    head.appendChild(gsiClient);
  }

  public removeGoogleClientScript(): void {
    const gsiClientScript = document.getElementById(GOOGLE_CLIENT_SCRIPT_ID);
    if (!gsiClientScript) {
      return;
    }
    gsiClientScript.parentNode.removeChild(gsiClientScript);
  }

  public async gsiClientCredentialResponseCallback(googleCredResponse: TGoogleCredentialResponse): Promise<void> {
    if (!googleCredResponse || !googleCredResponse.credential || !this.isGoogleAuthoritativeForCredential(googleCredResponse.credential)) {
      this.showGeneralAuthFailMessage('Google');
      return;
    }

    await this.loginByGoogle({
      token: googleCredResponse.credential,
      device_id: this.deviceId,
      platform: 'WEB',
    });

    if (this.isAuthenticated) {
      this.$emit('oauth-success');

    }

    // TODO: check for authStore/authError, display showGeneralAuthFailMessage
  }

  public loadFacebookSDKOnce(): void {
    if (document.getElementById(FACEBOOK_SDK_SCRIPT_ID)) {
      return;
    }

    this.addFacebookInitFunction();

    const firstScript = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    js.id = FACEBOOK_SDK_SCRIPT_ID;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    firstScript.parentNode.insertBefore(js, firstScript);
  }

  public addFacebookInitFunction(): void {
    (window as any).fbAsyncInit = function(): void {
      try {
        (window as any).FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v15.0'
        });
        (window as any).FB.AppEvents.logPageView();
      } catch {
        // ignore
      }
    };
  }

  public removeFacebookSDKScript(): void {
    const fbSDK = document.getElementById(FACEBOOK_SDK_SCRIPT_ID);
    if (fbSDK) {
      fbSDK.parentNode.removeChild(fbSDK);
    }
  }

  public onFacebookLoginClick(): void {
    try {
      (window as any).FB.login((response: TFacebookCredentialResponse): void => {
        if (response.authResponse) {
          this.handleFacebookLoginSuccess(response);
        } else {
          this.showGeneralAuthFailMessage('Facebook');
        }
      }, {scope: 'public_profile,email'});
    } catch {
      this.showGeneralAuthFailMessage('Facebook');
    }
  }

  public async handleFacebookLoginSuccess(fbCredResponse: TFacebookCredentialResponse): Promise<void> {
    if (!fbCredResponse || !fbCredResponse.authResponse || !fbCredResponse.authResponse.accessToken) {
      this.showGeneralAuthFailMessage('Facebook');
      return;
    }

    await this.loginByFacebook({
      token: fbCredResponse.authResponse.accessToken,
      device_id: this.deviceId,
      platform: 'WEB',
    });

    if (this.isAuthenticated) {
      this.$emit('oauth-success');

    }

    // TODO: check for authStore/authError, display showGeneralAuthFailMessage
  }

  public onLinkedInLoginClick(): void {
    // N.B.: docs https://learn.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fcontext&tabs=HTTPS
    const w = 500;
    const h = 670;
    const top = window.innerHeight / 2 - h / 2;
    const left = window.innerWidth / 2 - w / 2;
    this.linkedInAuthWindow = window.open(LINKEDIN_OAUTH_INIT_URL, 'oauthLinkedIn', 'width=' + w + ',height=' + h + ',left=' + left + 'top=' + top);

    this.initLinkedInPostmessageHandling();
  }

  public initLinkedInPostmessageHandling(): void {
    window.addEventListener('message', this.onLinkedInAuthPostmessage);
  }

  public async onLinkedInAuthPostmessage(e: MessageEvent): Promise<void> {
    if (e && e.data && (e.data as TLinkedInPostMessageData).event_name !== 'ew-linkedin-message') {
      return;
    }

    if (!e || !e.data || !e.data.code || e.origin !== 'https://www.eventswallet.com') {
      if (this.linkedInAuthWindow) {
        this.linkedInAuthWindow.close();
      }
      this.stopLinkedInPostmessageHandling();
      this.showGeneralAuthFailMessage('LinkedIn');
      return;
    }

    await this.loginByLinkedIn({
      token: e.data.code,
      device_id: this.deviceId,
      platform: 'WEB',
    });

    if (this.linkedInAuthWindow) {
      this.linkedInAuthWindow.close();
    }
    this.stopLinkedInPostmessageHandling();

    if (this.isAuthenticated) {
      this.$emit('oauth-success');

    }
  }

  public stopLinkedInPostmessageHandling(): void {
    window.removeEventListener('message', this.onLinkedInAuthPostmessage);
  }

  public showGeneralAuthFailMessage(socialNetworkName: string): void {
    this.oauthProviderNameForErrorMessage = socialNetworkName;
    this.isErrorVisible = true;

    window.setTimeout(this.hideGeneralAuthFailMessage, 7000);
  }

  public hideGeneralAuthFailMessage(): void {
    this.isErrorVisible = false;
    this.oauthProviderNameForErrorMessage = '';
  }

  public getGoogleCredentialPayload(cred: string): any {
    if (!cred) {
      return null;
    }
    const credPayload: string = this.base64DecodeUnicode(cred.split('.')[1] || '') || '';
    return JSON.parse(credPayload) as any;
  }

  public isGoogleAuthoritativeForCredential(cred: string): boolean {
    // N.B.: see https://developers.google.com/identity/gsi/web/reference/js-reference#credential
    const credPayload: any = this.getGoogleCredentialPayload(cred);
    if (!credPayload) {
      return false;
    }

    const isGmailEmail = /@gmail\.com$/.test(credPayload.email || '');
    const isGSuiteAccount = !!(credPayload.hd) && (credPayload.email_verified === true);

    return isGmailEmail || isGSuiteAccount;
  }

  public base64DecodeUnicode(str: string): string {
    const percentEncodedStr = atob(str).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('');

    return decodeURIComponent(percentEncodedStr);
  }
}
