


import { Vue, Component, Ref } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { Validations } from 'vuelidate-property-decorators';
import { TEvent } from '@/_types/event.type';
import { TUser } from '@/_types/user.type';
import loginApi, {TPasswordChangeConfirmRequestParams, TSendLoginRequestParams} from '@/_api/login/login.api';
import { TContact } from '@/_types/contact.type';
import { Location } from 'vue-router';
import {
  MENU_PROPERTIES_KEY_NAME,
} from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwPassword from '@/_modules/standalone-company/components/UI/ew-password/ew-password.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import EwCheckbox from '@/_modules/standalone-company/components/UI/ew-checkbox/ew-checkbox.vue';
import OauthButtons from '@/_components/oauth-buttons/oauth-buttons.vue';
import HelpCrunchService from '@/_services/help-crunch.service';
import {TEventSettings} from '@/_types/event-settings.type';
import UtilsHelper from '@/_helpers/utils.helper';
import {TActivateTicketCodeParams} from '@/_modules/events/api/event/event.api';

enum AuthContentScreens {
  NONE = 'none',
  EMAIL_LOOKUP = 'email-lookup',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign_up',
  FORGOT_PASSWORD = 'forgot-password',
  TICKET_CODE = 'ticket-code',
}

type TEwInputComponent = {
  inputElement: HTMLInputElement;
}

const ENVIRONMENT_NAME = process.env.VUE_APP_ENV;
const RESEND_CODE_SECONDS = 50;
const PASSWORD_MIN_LENGTH = 5;

@Component({
  components: {
    EwInput,
    EwPassword,
    EwButton,
    EwCheckbox,
    OauthButtons,
  }
})
export default class AuthContent extends Vue {

  @Validations()
  public readonly validations: TVuelidateRuleSet<string> | any = {
    email: {
      email: email,
      required,
    },
    password: { minLength: minLength(PASSWORD_MIN_LENGTH) },
  };

  @Ref('emailLookupInput') emailLookupInput: TEwInputComponent;
  @Ref('signInPasswordInput') signInPasswordInput: TEwInputComponent;
  @Ref('signUpPasswordInput') signUpPasswordInput: TEwInputComponent;
  @Ref('confirmCodePart0') confirmCodePart0: TEwInputComponent;
  @Ref('confirmCodePart1') confirmCodePart1: TEwInputComponent;
  @Ref('confirmCodePart2') confirmCodePart2: TEwInputComponent;
  @Ref('confirmCodePart3') confirmCodePart3: TEwInputComponent;

  @Getter('authStore/isLoading') isAuthLoading: boolean;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/userData') user: TUser;
  @Getter('authStore/deviceId') deviceId: string;
  @Getter('authStore/authError') authError: ApiErrorResponseData;
  @Getter('authStore/authPopupTargetRoute') authTargetRoute: Location;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;

  @Getter('promoPageStore/contact') myself: TContact;

  @Action('authStore/emailLookup') emailLookup: (params: { email: string }) => Promise<{ is_registered: boolean }>;
  @Action('authStore/signIn') signIn: (params: TSendLoginRequestParams) => Promise<void>;
  @Action('authStore/signUp') signUp: (params: TSendLoginRequestParams) => Promise<void>;
  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => Promise<void>;
  @Action('authStore/changePassword') changePassword: (payload: TPasswordChangeConfirmRequestParams) => Promise<void>;
  @Action('authStore/forgotPassword') sendConfirmationCodeToEmail: (email: string) => Promise<void>;
  @Action('authStore/setContactInfoInviterVisibility') setContactInfoInviterVisibility: (isVisible: boolean) => Promise<void>;
  @Getter('authStore/confirmId') confirmationId: string;
  @Getter('authStore/forgotPasswordRequestErrorText') sendConfirmationCodeToEmailErrorText: string;
  @Getter('authStore/changePasswordRequestErrorText') changePasswordRequestErrorText: string;

  @Action('_eventStore/refreshAsync') refreshEvent: () => Promise<void>;
  @Action('_eventStore/activateTicketCode') activateTicketCode: (params: TActivateTicketCodeParams) => Promise<boolean>;
  @Action('_eventStore/showBecomingAContactConfirm') showBecomingAContactConfirm: () => void;
  @Action('_eventStore/hideBecomingAContactConfirm') hideBecomingAContactConfirm: () => void;
  @Action('_eventStore/confirmBecomingAContact') confirmBecomingAContact: () => void;
  @Action('_eventStore/cancelBecomingAContact') cancelBecomingAContact: () => void;
  @Action('_eventStore/setPrivateEventDialogVisibility') setPrivateEventDialogVisibility: (isVisible: boolean) => void;

  @Action('promoPageStore/getContact') getContact: (params: { eventId: number }) => Promise<TContact>;

  public activeAuthScreen: AuthContentScreens = AuthContentScreens.NONE;
  public isLoadingIndicatorForceVisible: boolean = false;

  public email: string = '';
  public password: string = '';
  public passwordMinLength: number = PASSWORD_MIN_LENGTH;
  public isAgreedToEmailing: boolean = false;
  public ticketCode: string = '';

  public emailIsIncorrectMessage: string = '';
  public passwordIsIncorrectMessage: string = '';
  public ticketCodeIsIncorrectMessage: string = '';
  public whereToGetCodeMessage: string = '';

  public isConfirmCodeVerified: boolean = false;
  public confirmCodeParts: string[] = [];
  public resendCodeCounter: number = RESEND_CODE_SECONDS;
  public resendCodeCounterIntervalId: number;

  public get isAuthContentHidden(): boolean {
    return this.activeAuthScreen === AuthContentScreens.NONE;
  }

  public get isAuthLoadingCurtainVisible(): boolean {
    return this.isAuthLoading || this.isLoadingIndicatorForceVisible;
  }

  public get isEmailLookupScreenVisible(): boolean {
    return this.activeAuthScreen === AuthContentScreens.EMAIL_LOOKUP;
  }

  public get isSignInScreenVisible(): boolean {
    return this.activeAuthScreen === AuthContentScreens.SIGN_IN;
  }

  public get isSignUpScreenVisible(): boolean {
    return this.activeAuthScreen === AuthContentScreens.SIGN_UP;
  }

  public get isForgotPasswordScreenVisible(): boolean {
    return this.activeAuthScreen === AuthContentScreens.FORGOT_PASSWORD;
  }

  public get isActivateTicketCodeScreenVisible(): boolean {
    return this.activeAuthScreen === AuthContentScreens.TICKET_CODE;
  }

  public get isOauthButtonsVisible(): boolean {
    return this.isEventsWalletDomain && !this.isCN;
  }

  public get isEventsWalletDomain(): boolean {
    const originDomain: string = ((window && window.location && window.location.origin) || '')
      .replace(window.location.protocol, '')
      .replace('//', '');
    return originDomain.indexOf('eventswallet.') >= 0;
  }

  public get isCN(): boolean {
    return (ENVIRONMENT_NAME === 'cn');
  }

  public get apiAuthErrorText(): string {
    return (this.authError && this.authError.error) || '';
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isAuthOnEventPage(): boolean {
    return !!this.eventId;
  }

  public get isSupportChatEnabled(): boolean {
    return (this.eventSettingsLayout && !!this.eventSettingsLayout.is_support_chat_enabled) || false;
  }

  public get isEventEnabled(): boolean {
    return !!(this.event && this.event.is_enabled);
  }

  public get isEventPrivate(): boolean {
    return !!(this.event && this.event.is_private);
  }

  public get isEventOwner(): boolean {
    return !!(this.event && this.event.personal && this.event.personal.is_creator && this.myself && this.myself.id);
  }

  public get hasPersonalEventAccess(): boolean {
    return !!(this.event && this.event.personal && this.event.personal.has_access && this.myself && this.myself.id);
  }

  public get eventSettingsLayout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get defaultEventRouteName(): string {
    return UtilsHelper.getRouteNameByEventSettingsKey(this.eventSettingsDefaultScreen || '')
      || UtilsHelper.getRouteNameByEventSettingsKey(this.firstShownRouteName || '')
      || 'event-info';
  }

  public get eventSettingsDefaultScreen(): string {
    const defaultScreenNameFromSettings: string = (this.eventSettingsLayout && this.eventSettingsLayout.defaultScreen);
    return defaultScreenNameFromSettings || null;
  }

  public get firstShownRouteName(): string {
    const menuProps = (this.eventSettingsLayout && this.eventSettingsLayout[MENU_PROPERTIES_KEY_NAME]) || null;
    if (!menuProps) {
      return null;
    }
    const firstShown: string = Object.keys(menuProps)
      .find(key => !!(menuProps[key] && menuProps[key].isShown === true));
    return firstShown || null;
  }

  public get isRestoreButtonDisabled(): boolean {
    return !(/^\d{4}$/.test(this.confirmCode)) || !this.password;
  }

  public get confirmCode(): string {
    if (this.confirmCodeParts[0] && this.confirmCodeParts[1] && this.confirmCodeParts[2] && this.confirmCodeParts[3]) {
      return this.confirmCodeParts.join('');
    }
    return '';
  }

  public get resendCodeCounterFormatted(): string {
    return '00:' + this.resendCodeCounter.toFixed(0).padStart(2, '0');
  }

  // -==================================================================- // TODO: remove this comment line

  public mounted(): void {
    this.initVisibleScreen();
  }

  public initVisibleScreen(): void {
    if (this.isAuthLoading) {
      window.setTimeout(this.initVisibleScreen, 250);
      return;
    }

    if (!this.isAuthenticated) {
      this.showAuthScreen(AuthContentScreens.EMAIL_LOOKUP);
      this.$nextTick(() => { this.emailLookupInput.inputElement.focus(); });
    } else {
      this.onAlreadyAuthenticated();
    }
  }

  public showAuthScreen(screenName: AuthContentScreens): void {
    this.activeAuthScreen = screenName || AuthContentScreens.NONE;
    this.$emit('new-title', this.getTitleByScreen(screenName));
  }

  public getTitleByScreen(screenName: AuthContentScreens): string {
    switch (screenName) {
      case AuthContentScreens.EMAIL_LOOKUP:
        return this.$t('ewAuth.signInSignUpTitle') as string;
      case AuthContentScreens.SIGN_IN:
        return this.$t('ewAuth.signInTitle') as string;
      case AuthContentScreens.SIGN_UP:
        return this.$t('authPage.authHeadline') as string;
      case AuthContentScreens.FORGOT_PASSWORD:
        return this.$t('authPage.screens.forgotPassword.headline') as string;
      case AuthContentScreens.TICKET_CODE:
        return this.$t('auth.hasAccessScreenTitle') as string; // TODO: better naming of the translation key
      default:
        return '';
    }
  }

  public showLoadingCurtain(): void {
    this.isLoadingIndicatorForceVisible = true;
  }

  public hideLoadingCurtain(): void {
    this.isLoadingIndicatorForceVisible = false;
  }

  public async onEmailLookup(): Promise<void> {
    if (!this.$v.email.email || !this.email) {
      this.emailIsIncorrectMessage = this.$t(!this.email ? 'errors.validation.field_is_required' : 'errors.validation.email_invalid') as string;
      return;
    }

    this.showLoadingCurtain();
    const emailLookupResponse = await this.emailLookup({ email: this.email });
    this.hideLoadingCurtain();

    if (this.authError) {
      this.emailIsIncorrectMessage = 'Too many requests'; // TODO: add translations
      return;
    }

    if (emailLookupResponse) {
      if (emailLookupResponse.is_registered) {
        this.showAuthScreen(AuthContentScreens.SIGN_IN);
        this.$nextTick(() => {
          this.signInPasswordInput.inputElement.focus();
        });
      } else {
        this.showAuthScreen(AuthContentScreens.SIGN_UP);
        this.$nextTick(() => {
          this.signUpPasswordInput.inputElement.focus();
        });
      }
    }
  }

  public async onSignInAttempt(): Promise<void> {
    if (!this.password || !this.email) {
      return;
    }

    this.showLoadingCurtain();
    await this.signIn({
      login: this.email,
      password: this.password,
      platform: 'WEB',
      device_id: this.deviceId,
    });
    this.hideLoadingCurtain();

    if (this.apiAuthErrorText) {
      this.passwordIsIncorrectMessage = this.apiAuthErrorText;
      if (this.apiAuthErrorText === 'Wrong login or password!') {
        this.passwordIsIncorrectMessage = this.$t('authPage.screens.phoneEmail.errors.wrongLoginOrPassword') as string;
      }
      return;
    }

    this.dataLayerPush({
      event: 'sign-in-form-submit',
      formType: 'sign-in'
    });

    if (this.isAuthenticated) {
      await this.onAlreadyAuthenticated();
    }
  }

  public async onSignUpAttempt(): Promise<void> {
    if (!this.$v.password.minLength) {
      this.passwordIsIncorrectMessage = 'Password is too short'; // TODO: add translations
      return;
    }

    if (!this.isAgreedToEmailing) {
      return;
    }

    this.showLoadingCurtain();
    await this.signUp({
      login: this.email,
      password: this.password,
      platform: 'WEB',
      device_id: this.deviceId,
    });
    this.hideLoadingCurtain();

    if (this.apiAuthErrorText) {
      this.passwordIsIncorrectMessage = this.apiAuthErrorText;
      return;
    }

    this.dataLayerPush({
      event: 'registration-form-submit',
      formType: 'registration'
    });

    if (this.isAuthenticated) {
      await this.onAlreadyAuthenticated();
    }
  }

  public async onAlreadyAuthenticated(): Promise<void> {
    if (!this.isAuthenticated) {
      return;
    }

    this.isLoadingIndicatorForceVisible = true;
    if (this.eventId) {
      await this.manageAuthOnEventPage();
      this.isLoadingIndicatorForceVisible = false;
      return;
    }

    await this.redirectOnAuthSuccess();
    this.isLoadingIndicatorForceVisible = false;
    await this.setStandaloneAuthVisibility(false);
    this.showAuthScreen(AuthContentScreens.NONE);
  }

  public async manageAuthOnEventPage(): Promise<void> {
    await this.getContact({ eventId: this.eventId });

    if (this.isEventEnabled) {
      if (!this.myself) {
        if (this.event.access_type === 'free') {
          this.showAuthScreen(AuthContentScreens.NONE);
          await this.showBecomingAContactConfirm();
          await this.setStandaloneAuthVisibility(false);
          this.showAuthScreen(AuthContentScreens.NONE);
          return;
        }

        if (this.event.access_type === 'register') {
          await this.showAuthScreen(AuthContentScreens.TICKET_CODE);
          return;
        }
      }
    }

    await this.refreshEvent();

    if (!this.isEventEnabled) {
      await this.onAccessDisabledEventAttempt();
      await this.setStandaloneAuthVisibility(false);
      this.showAuthScreen(AuthContentScreens.NONE);
      return;
    }

    if (this.hasPersonalEventAccess) {
      await this.redirectOnAuthSuccess();
      await this.setStandaloneAuthVisibility(false);
      this.showAuthScreen(AuthContentScreens.NONE);
      await this.setContactInfoInviterVisibility(true);
      return;
    }

    if (this.isEventPrivate) {
      await this.onAccessPrivateEventAttempt();

    }
  }

  public async redirectOnAuthSuccess(): Promise<void> {

    this.isLoadingIndicatorForceVisible = true;

    if (this.authTargetRoute) {
      const route: any = (this.authTargetRoute as unknown) as any;
      if (route.name) {
        await this.$router.push(this.authTargetRoute).catch(() => { /* ignore */ });
      } else if (typeof route === 'string') {
        await this.$router.push({
          path: route as string,
        }).catch(() => { /* ignore */ });
      }
    } else {
      await this.$router.push({
        name: this.isAuthOnEventPage ? this.defaultEventRouteName : 'event-list-my',
      }).catch(() => { /* ignore */ });
    }
  }

  public async onAccessDisabledEventAttempt(): Promise<void> {
    if (!this.isEventOwner) {
      this.setPrivateEventDialogVisibility(true);
      return;
    }

    await this.redirectOnAuthSuccess();
  }

  public async onAccessPrivateEventAttempt(): Promise<void> {
    if (!this.isEventOwner || !this.myself) {
      this.setPrivateEventDialogVisibility(true);
      return;
    }

    await this.redirectOnAuthSuccess();
  }

  public onForgotPasswordClick(): void {
    this.getConfirmationId();
    this.startResendCodeCounter();
    this.password = '';
    this.showAuthScreen(AuthContentScreens.FORGOT_PASSWORD);
  }

  public async onSendTicketCodeAttempt(): Promise<void> {

    this.showLoadingCurtain();
    const isTicketCodeAccepted = await this.activateTicketCode({
      eventId: this.eventId,
      code: this.ticketCode,
    });

    if (!isTicketCodeAccepted) {
      this.ticketCodeIsIncorrectMessage = this.$t('promoAccessCheck.accessNotGranted') as string;
      this.hideLoadingCurtain();
      return;
    }

    this.whereToGetCodeMessage = '';
    await this.refreshEvent();
    await this.getContact({ eventId: this.eventId });
    await this.$store.dispatch('promoPageStore/refresh'); // AW-1622
    this.hideLoadingCurtain();
    await this.redirectOnAuthSuccess();
    await this.setStandaloneAuthVisibility(false);
    this.showAuthScreen(AuthContentScreens.NONE);
  }

  public onWhereToGetCodeClick(): void {
    this.whereToGetCodeMessage = this.$t('authPage.screens.answerWhereGetCode') as string;
  }

  public onResendCodeClick(): void {
    if (this.resendCodeCounter <= 0) {
      loginApi.resendCode({ confirmation_id: this.confirmationId });
      this.resendCodeCounter = RESEND_CODE_SECONDS;
    }
  }

  public startResendCodeCounter(): void {
    this.resendCodeCounterIntervalId = window.setInterval(() => {
      if (this.resendCodeCounter > 0) {
        this.resendCodeCounter--;
        return;
      }
      this.resendCodeCounter = 0;
    }, 1000);
  }

  public stopResendCodeCounter(): void {
    window.clearInterval(this.resendCodeCounterIntervalId);
    this.resendCodeCounter = RESEND_CODE_SECONDS;
  }

  public async onChangePasswordAttempt(): Promise<void> {
    if (!this.isConfirmCodeVerified) {
      return; // TODO: should we display an error?
    }

    this.showLoadingCurtain();
    await this.changePassword({
      confirmation_id: this.confirmationId,
      code: this.confirmCode,
      password: this.password,
      platform: 'WEB',
      device_id: this.deviceId,
    });

    if (this.changePasswordRequestErrorText) {
      this.passwordIsIncorrectMessage = this.changePasswordRequestErrorText;
      this.hideLoadingCurtain();
      return;
    }

    if (this.isAuthenticated) {
      await this.onAlreadyAuthenticated();
    }

    this.hideLoadingCurtain();
  }

  public onBackFromForgotPasswordClick(): void {
    this.stopResendCodeCounter();
    this.password = '';
    this.showAuthScreen(AuthContentScreens.SIGN_IN);
    this.$nextTick(() => {
      this.signInPasswordInput.inputElement.focus();
    });
  }

  public async getConfirmationId(): Promise<void> {
    await this.sendConfirmationCodeToEmail(this.email);
    if (this.confirmationId && !this.sendConfirmationCodeToEmailErrorText) {
      this.isConfirmCodeVerified = true;
    }
    if (this.sendConfirmationCodeToEmailErrorText) {
      this.passwordIsIncorrectMessage = this.sendConfirmationCodeToEmailErrorText;
    }
  }

  public async onOauthSuccess(): Promise<void> {
    await this.onAlreadyAuthenticated();
  }

  public openSupportChat(): void {
    if (!this.isSupportChatEnabled) {
      return;
    }
    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) {
      window.setTimeout(() => { this.openSupportChat(); }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

  public dataLayerPush(options: any): void {
    const dataLayer = (window && (window as any).dataLayer) || [];
    try {
      dataLayer.push(options);
    } catch {}
  }
}
