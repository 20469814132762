import { ActionContext, Module } from 'vuex';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TEventListStoreState } from '@/_types/store/event-list-store-state.type';
import { TEvent } from '@/_types/event.type';
import eventApi from '@/_modules/events/api/event/event.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TGetEventListParams} from '@/_types/get-event-list-params.type';

const getPublicEventListRequest = new AxiosCancellableRequest<TGetEventListParams, TApiListResponse<TEvent>>(eventApi.getPublicEvents.bind(eventApi));
const getMyEventListRequest = new AxiosCancellableRequest<TGetEventListParams, TApiListResponse<TEvent>>(eventApi.getMyEvents.bind(eventApi));
const getPastEventListRequest = new AxiosCancellableRequest<TGetEventListParams, TApiListResponse<TEvent>>(eventApi.getMyEvents.bind(eventApi));

const eventListStore: Module<TEventListStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventListEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    eventListPastEntity: {
      data: null,
      isLoading: false,
      error: null
    },
  },
  getters: {
    eventList: (state: TEventListStoreState): TEvent[] => {
      return (state.eventListEntity.data && state.eventListEntity.data.List) || [];
    },
    eventListPast: (state: TEventListStoreState): TEvent[] => {
      return (state.eventListPastEntity.data && state.eventListPastEntity.data.List) || [];
    },
    isEventListLoading: (state: TEventListStoreState): boolean => {
      return state.eventListEntity.isLoading || state.eventListPastEntity.isLoading;
    },
  },
  actions: {
    getMyEvents: async (context: ActionContext<TEventListStoreState, TAppStoreState>, payload: TGetEventListParams): Promise<TApiListResponse<TEvent>> => {
      const { commit, state } = context;

      if (state.eventListEntity.data) {
        return state.eventListEntity.data;
      }

      if (state.eventListEntity.isLoading) {
        try {
          return await getMyEventListRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventListRequest');
      let data;
      try {
        data = await getMyEventListRequest.load(payload || null);
        return data;
      } catch (error) {
        commit('eventListError', error);
        return null;
      } finally {
        commit('setEventList', data);
      }
    },

    getPastEvents: async (context: ActionContext<TEventListStoreState, TAppStoreState>, payload: TGetEventListParams): Promise<TApiListResponse<TEvent>> => {
      const { commit, state } = context;

      if (state.eventListPastEntity.data) {
        return state.eventListPastEntity.data;
      }

      if (state.eventListPastEntity.isLoading) {
        try {
          return await getPastEventListRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventListPastRequest');
      let data;
      try {
        data = await getPastEventListRequest.load(payload || null);
        return data;
      } catch (error) {
        commit('eventListPastError', error);
        return null;
      } finally {
        commit('setEventListPast', data);
      }
    },

    getPublicEvents: async (context: ActionContext<TEventListStoreState, TAppStoreState>, payload: TGetEventListParams): Promise<TApiListResponse<TEvent>> => {
      const { commit, state } = context;

      if (state.eventListEntity.data) {
        return state.eventListEntity.data;
      }

      if (state.eventListEntity.isLoading) {
        try {
          return await getPublicEventListRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventListRequest');
      let data;
      try {
        data = await getPublicEventListRequest.load(payload || null);
        return data;
      } catch (error) {
        commit('eventListError', error);
        return null;
      } finally {
        commit('setEventList', data);
      }
    },
  },
  mutations: {

    refresh(state: TEventListStoreState): void {
      state.eventListEntity.data = null;
      state.eventListEntity.isLoading = false;
      state.eventListEntity.error = null;
      state.eventListPastEntity.data = null;
      state.eventListPastEntity.isLoading = false;
      state.eventListPastEntity.error = null;
    },

    eventListRequest(state: TEventListStoreState): void {
      state.eventListEntity.isLoading = true;
    },

    eventListError(state: TEventListStoreState, newVal: ApiErrorResponseData): void {
      state.eventListEntity.error = newVal;
      state.eventListEntity.isLoading = false;
    },

    setEventList(state: TEventListStoreState, newVal: TApiListResponse<TEvent>): void {
      state.eventListEntity.data = newVal;
      state.eventListEntity.isLoading = false;
    },

    eventListPastRequest(state: TEventListStoreState): void {
      state.eventListPastEntity.isLoading = true;
    },

    eventListPastError(state: TEventListStoreState, newVal: ApiErrorResponseData): void {
      state.eventListPastEntity.error = newVal;
      state.eventListPastEntity.isLoading = false;
    },

    setEventListPast(state: TEventListStoreState, newVal: TApiListResponse<TEvent>): void {
      state.eventListPastEntity.data = newVal;
      state.eventListPastEntity.isLoading = false;
    },

  },
};

export default eventListStore;
