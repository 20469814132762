import { APIError, ErrTextByCode } from '@/utils/ErrorsAPI';

import axios from 'axios';
import * as FormData from 'form-data';
import router from '../router';
import store from '../store';
import i18n from '@/i18n';
import CookiesHelper from "@/_helpers/cookies-helper";
import NotesHelper from "@/_helpers/notes.helper";
import EventHelper from "@/_helpers/event.helper";

let baseUrl = process.env.VUE_APP_API_BASE_URL;

const getAuthToken = () => {
  return (store && store.state.authStore.token) || CookiesHelper.readCookie( 'user-token') || ''
}

const getCurrentLocaleName = () => {
  return i18n.locale;
}

function responseError(error) {
  const { response } = error;
  let errorRespObj = {
    result: false,
    code: 0,
    error: '',
    message_key: null,
    request_id: ''
  };

  if (response) {
    const { data, status, message } = response;
    if (data) {
      errorRespObj = Object.assign(errorRespObj, data);
      errorRespObj.message_key = ErrTextByCode(errorRespObj.code, errorRespObj.error);
      if (status === 401) {
        store.dispatch('authStore/authLogout');
        router.push({ name: 'auth-page' });
      }
    } else {
      errorRespObj.error = message;
      errorRespObj.message_key = ErrTextByCode(errorRespObj.code, errorRespObj.error);
    }
  }

  return new APIError(errorRespObj);
}

const api = {
  createEvent: {
    country: function () {
      const url = `${baseUrl}/country/list`;

      return new Promise(((resolve, reject) => {
        axios.get(url, {headers: {'X-Token-Key': getAuthToken() }})
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    city: function (data) {
      const url = `${baseUrl}/city/list`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: {country_id: data.country_id},
        method: 'GET'
      };

      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          });
      }))
    },
    uploadImages: function (data) {
      const url = `${baseUrl}/upload/file`;
      let formData = new FormData();
      formData.append("file", data.image);
      const resizeParams = {};
      if (data.image.w) {
        resizeParams.w = data.image.w;
      }
      if (data.image.h) {
        resizeParams.h = data.image.h;
      }
      if (data.image.h && data.image.w && data.image.mode) {
        resizeParams.mode = data.image.mode;
      }

      return new Promise(((resolve, reject) => {
        axios.post(url, formData, {
          timeout: 86400 * 1000,
          headers: {
            ...formData.getHeaders,
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            'X-Token-Key': getAuthToken(),
          },
          params: resizeParams
        })
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          });
      }))
    },
    uploadFiles: function (data) {
      const url = `${baseUrl}/upload/file`;
      let formData = new FormData();
      formData.append('file', data.file);
      const resizeParams = {};
      if (data.file.w) {
        resizeParams.w = data.file.w;
      }
      if (data.file.h) {
        resizeParams.h = data.file.h;
      }
      if (data.file.h && data.file.w && data.file.mode) {
        resizeParams.mode = data.file.mode;
      }

      return new Promise(((resolve, reject) => {
        axios.post(url, formData, {
          timeout: 86400 * 1000,
          headers: {
            ...formData.getHeaders,
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            'X-Token-Key': getAuthToken(),
          },
          params: resizeParams
        })
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          });
      }))
    },
  },
  updateEvent: {
    update: function (data) {
      const url = `${baseUrl}/event/${data.id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data.formData,
        method: 'PATCH'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {

            // temporary hack for consistent data - TODO: removeme
            store.dispatch('_eventStore/refresh');

            resolve(response.data);
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
  },
  eventList: {
    eventList: function (data, queryType = 'own') {
      let url = `${baseUrl}/event/list`;
      if (queryType === 'all') {
        url = `${baseUrl}/event/list/all`;
        /* This check needed because otherwise, when token is NULL, [object Object] gets passed to backend
           We've decided to allow unauthorized access to All events
         */
      }
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            response.data.List = response.data.List.map(item => {
              return EventHelper.responseToEventConverter(item);
            });
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    /* Gets single event info from backend */
    event: function (data) {
      const url = `${baseUrl}/event/${data.id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        // params: data.eventReq,
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            response.data = EventHelper.responseToEventConverter(response.data);
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
  },
  notes: {
    addNote: function (data) {
      let event_id = data.event_id;
      const url = `${baseUrl}/event/${event_id}/note`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'POST'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    editNote: function (data) {
      let id = data.note_id;
      const url = `${baseUrl}/note/${id}`;
      delete data.note_id;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PATCH'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    removeNote: function (data) {
      const url = `${baseUrl}/note/${data}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    addFav: function (data) {
      const url = `${baseUrl}/note/fav/${data}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'PUT'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    removeFav: function (data) {
      const url = `${baseUrl}/note/fav/${data}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    noteList: function (data) {
      const url = `${baseUrl}/event/${data.event_id}/note/list`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            response.data.List = response.data.List.map(item => {
              return NotesHelper.responseToNoteItemConverter(item);
            });

            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    noteListAll: function (data) {
      const url = `${baseUrl}/event/${data.event_id}/note/list/all`;
      delete data.event_id;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            response.data.List = response.data.List.map(item => {
              return NotesHelper.responseToNoteItemConverter(item);
            });

            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
  },
  promoPage: {
    promoPageCreate: function (data) {
      const url = `${baseUrl}/event/${data.event_id}/promopage/create`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data.payload,
        method: 'POST'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
          reject(responseError(error))
          })
      }))
    },
    promoPageList: function ({ event_id, cancelToken }) {
      let url = `${baseUrl}/event/${event_id}/promopage/list`;

      const config = {
        url: url,
        headers: {
          'X-Token-Key': getAuthToken(),
          'Accept-Language': getCurrentLocaleName(),
        },
        method: 'GET',
        cancelToken,
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    promoPageListAll: function ({ event_id, cancelToken }) {
      // N.B. Used by companies-map.vue, also see AW-2604
      let url = `${baseUrl}/event/${event_id}/promopage/list/all`;

      const config = {
        url: url,
        headers: {
          'X-Token-Key': getAuthToken(),
          'Accept-Language': getCurrentLocaleName(),
        },
        method: 'GET',
        params: {
          limit: 1000, // see AW-2604
        },
        cancelToken,
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data);
          }).catch((error) => {
            reject(responseError(error));
          })
      }))
    },
    getPromoPage: function ({ eventId, externalId, cancelToken, lang }) {
      const url = `${baseUrl}/event/${eventId}/promopage/${externalId}`;
      const config = {
        url: url,
        headers: {
          'X-Token-Key': getAuthToken(),
          'Accept-Language': lang ? lang : getCurrentLocaleName(),
        },
        method: 'GET',
        cancelToken,
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data);
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    getPromoPageContactId: function (event_id, contact_id, acceptLanguage) {
      const url = `${baseUrl}/event/${event_id}/contact/${contact_id}/promopage`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken()},
        method: 'GET'
      };
      // Parameter needed to show a desired promoPage lang independent of page locale
      config.headers['Accept-Language'] = acceptLanguage || getCurrentLocaleName();
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    updatePromoPage: function (data) {
      const url = `${baseUrl}/event/${data.event_id}/promopage/${data.external_id}`;
      delete data.event_id;
      delete data.external_id;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PATCH'
      };

      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {

            // temporary hack for consistent data - TODO: removeme
            store.dispatch('promoPageStore/promoPageUpdated');

            resolve(response.data);
          }).catch((error) => {
            reject(responseError(error))
          });
      }))
    },
    putUserPromoPage: function (data) {
      let {eventId, externalId, code} = data;
      const url = `${baseUrl}/event/${eventId}/promopage/${externalId}/code`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: {code: code},
        method: 'PUT',
      };
      return new Promise(((resolve, reject) => {
        axios(config)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(responseError(error))
        })
      }))
    },
    deleteUserPromoPage: function (event_id, external_id, user_id) {
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/user/${user_id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    putFileUrlPromoPage: function (data, event_id, external_id) {
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/file`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PUT'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    deleteFileUrlPromoPage: function (file_id, event_id, external_id) {
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/file/${file_id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    putPhotoVideoUrlPromoPage: function (data, event_id, external_id) {
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/photo`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PUT'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    deletePhotoVideoUrlPromoPage: function (data, event_id, external_id) {
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/photo/${data.file_id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    putPromoPageProduct: function(event_id, external_id, data){
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/product`;
      const config = {
        url: url,
        params: data,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'PUT'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    patchPromoPageProduct: function(event_id, external_id, product_id, data){
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/product/${product_id}`;
      const config = {
        url: url,
        params: data,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'PATCH'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    deletePromoPageProduct: function(event_id, external_id, product_id){
      const url = `${baseUrl}/event/${event_id}/promopage/${external_id}/product/${product_id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    getPavilion: (payload) => {
      const {eventId} = payload;
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/pavilions`,
        headers: { 'X-Token-Key': getAuthToken() },
      }

      return new Promise((resolve, reject) => {
        axios(config)
        .then((response) =>{
          resolve(response.data);
        }).catch((error) => {
          reject(responseError(error))
        })
      })
    }
  },
  users: {
    editUserInfo: function (data) {
      const url = `${baseUrl}/user/info`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PATCH'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    getUserInfo: function () {
      const url = `${baseUrl}/user/info`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    getUserProfile: function () {
      const url = `${baseUrl}/user/profile`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    createUserCompany: function (data) {
      const url = `${baseUrl}/user/company`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'POST'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
          reject(responseError(error))
          })
      }))
    },
    deleteUserCompany: function (data) {
      const url = `${baseUrl}/user/company/${data.company_id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        //params: data,
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    editUserCompany: function (data) {
      const url = `${baseUrl}/user/company/${data.id}`;
      delete data.id;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PATCH'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    uploadFile: function (data) {
      const url = `${baseUrl}/user/file`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PUT'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    deleteFile: function (data) {
      const url = `${baseUrl}/user/file/${data.id}`;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        //params: data,
        method: 'DELETE'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then(() => {
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
  },
  messages: {
    callMessageList: function (data) {
      const url = `${baseUrl}/event/${data.event_id}/user/${data.user_id}/message/list`;
      delete data.event_id;
      delete data.user_id;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'GET'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
    sendMessage: function (data) {
      const url = `${baseUrl}/event/${data.event_id}/user/${data.user_id}/message`;
      delete data.event_id;
      delete data.user_id;
      const config = {
        url: url,
        headers: {'X-Token-Key': getAuthToken() },
        params: data,
        method: 'PUT'
      };
      return new Promise(((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(responseError(error))
          })
      }))
    },
  },
  statistics: {

    getStat: ({ eventId }) => {
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/stat`,
        headers: { 'X-Token-Key': getAuthToken() },
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          });
      });
    },

    getStatPersonal: ({ eventId }) => {
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/stat/personal`,
        headers: { 'X-Token-Key': getAuthToken() },
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          });
      });
    },

    getStatPromopage: ({ eventId, externalId }) => {
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/stat/promopage/${externalId}`,
        headers: { 'X-Token-Key': getAuthToken() },
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          });
      });
    },
    exportFileContact: ({ eventId, format }) => {
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/export/contacts`,
        headers: { 'X-Token-Key': getAuthToken() },
        responseType: 'blob',
        params: {
          format: format,
        },
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          });
      });
    },
    exportFilePromopage: ({ eventId, format }) => {
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/export/promopages`,
        headers: { 'X-Token-Key': getAuthToken() },
        responseType: 'blob',
        params: {
          format: format,
        },
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          });
      });
    },
    exportFileMessages: ({ eventId, format }) => {
      const config = {
        method: 'GET',
        url: `${baseUrl}/event/${eventId}/export/messages`,
        headers: { 'X-Token-Key': getAuthToken() },
        responseType: 'blob',
        params: {
          format: format,
        },
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(responseError(error))
          });
      });
    },
  }
};
export default api

