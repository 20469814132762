import { ActionContext, Module } from 'vuex';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TEventStoreState } from '@/_modules/events/types/event-store-state.type';
import {TEvent, TEventType} from '@/_types/event.type';
import eventApi, {
  TCreateContactTagRequestParams,
  TDeleteContactTagParams,
  TEditEventParams, TEventRestrictions,
  TGetEventParams,
  TGetEventSettingsParams,
  TGetListOfContactTagsParams,
  TPatchEventSettingsParams,
  TRegisterUserParams,
  TActivateTicketCodeParams,
} from '@/_modules/events/api/event/event.api';
import EventHelper from '@/_helpers/event.helper';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { TEventRequest } from '@/_modules/events/types/event.type';
import { TContactTag } from '@/_types/contact-tag.type';
import { TEventTag } from '@/_types/event-tag.type';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import { TEventSettings } from '@/_types/event-settings.type';
import momentTimezone from 'moment-timezone';

const getEventRequest = new AxiosCancellableRequest<TGetEventParams, TEvent>(eventApi.getEvent.bind(eventApi));
const createEventRequest = new AxiosCancellableRequest<TEventRequest, TEvent>(eventApi.createEvent.bind(eventApi));
const editEventRequest = new AxiosCancellableRequest<TEditEventParams, TEvent>(eventApi.editEvent.bind(eventApi));
const getContactTagsRequest = new AxiosCancellableRequest<TGetListOfContactTagsParams, TContactTag[]>(eventApi.getListOfContactTags.bind(eventApi));
const getEventTagsRequest = new AxiosCancellableRequest<TGetListOfContactTagsParams, TEventTag[]>(eventApi.getListOfEventTags.bind(eventApi));
const deleteContactTagRequest = new AxiosCancellableRequest<TDeleteContactTagParams, void>(eventApi.deleteContactTag.bind(eventApi));
const deleteEventTagRequest = new AxiosCancellableRequest<TDeleteContactTagParams, void>(eventApi.deleteEventTag.bind(eventApi));

const _eventStore: Module<TEventStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    eventEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    eventContactTags: {
      data: [],
      isLoading: false,
      error: null
    },
    eventTags: {
      data: [],
      isLoading: false,
      error: null
    },
    eventSettings: {
      data: null,
      isLoading: false,
      error: null,
    },
    eventTypes: {
      data: null,
      isLoading: false,
      error: null,
    },
    isEventSettingsPolled: false,
    isStreamingEnabled: false,
    obsSettingsDialogConfig: null,
    streamYardSettingsDialogConfig: null,
    zoomSettingsDialogConfig: null,
    embedCodeDialogConfig: null,
    isBroadcastTimeCheckDialogVisible: false,
    isChatRoomTimeCheckDialogVisible: false,
    eventRestrictions: null,
    isPrivateEventDialogVisible: false,
    selectedTimezone: '',
    isConfirmBecomingAContactPopupVisible: false,
    hasAgreedToBecomeAContact: false,
    isHelpCrunchReady: false,
  },
  getters: {
    eventId: (state: TEventStoreState): number => {
      return state.eventId;
    },
    event: (state: TEventStoreState): TEvent => {
      return state.eventEntity.data;
    },
    selectedTimezone: (state: TEventStoreState): string => {
      return state.selectedTimezone;
    },
    eventError: (state: TEventStoreState): ApiErrorResponseData => {
      return state.eventEntity.error;
    },
    eventSettingsError: (state: TEventStoreState): ApiErrorResponseData => {
      return state.eventSettings.error;
    },
    isLoading: (state: TEventStoreState): boolean => {
      return state.eventEntity.isLoading;
    },
    obsSettingsDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.obsSettingsDialogConfig;
    },
    streamYardSettingsDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.streamYardSettingsDialogConfig;
    },
    zoomSettingsDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.zoomSettingsDialogConfig;
    },
    embedCodeDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.embedCodeDialogConfig;
    },
    isBroadcastTimeCheckDialogVisible: (state: TEventStoreState): boolean => {
      return state.isBroadcastTimeCheckDialogVisible;
    },
    isChatRoomTimeCheckDialogVisible: (state: TEventStoreState): boolean => {
      return state.isChatRoomTimeCheckDialogVisible;
    },
    eventContactTags: (state: TEventStoreState): TContactTag[] => {
      return state.eventContactTags.data;
    },
    eventTags: (state: TEventStoreState): TEventTag[] => {
      return state.eventTags.data;
    },
    eventTypes: (state: TEventStoreState): TEventType[] => {
      return state.eventTypes.data || [];
    },
    isEventContactTagsLoading: (state: TEventStoreState): boolean => {
      return state.eventContactTags.isLoading;
    },
    isEventTagsLoading: (state: TEventStoreState): boolean => {
      return state.eventTags.isLoading;
    },
    eventSettings: (state: TEventStoreState): TEventSettings => {
      return state.eventSettings.data;
    },
    isEventSettingsPolled: (state: TEventStoreState): boolean => {
      return state.isEventSettingsPolled;
    },
    eventRestrictions: (state: TEventStoreState): TEventRestrictions => {
      return state.eventRestrictions;
    },
    isPrivateEventDialogVisible: (state: TEventStoreState): boolean => {
      return state.isPrivateEventDialogVisible;
    },
    isStreamingEnabled: (state: TEventStoreState): boolean => {
      return state.isStreamingEnabled;
    },
    isConfirmBecomingAContactPopupVisible: (state: TEventStoreState): boolean => state.isConfirmBecomingAContactPopupVisible,
    hasAgreedToBecomeAContact: (state: TEventStoreState): boolean => state.hasAgreedToBecomeAContact,
    isHelpCrunchReady: (state: TEventStoreState): boolean => state.isHelpCrunchReady,
  },
  actions: {
    reset: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    refresh: ({ commit, dispatch, state }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('refresh');
      dispatch('getEvent', state.eventId);
    },

    refreshAsync: async ({ commit, dispatch, state }: ActionContext<TEventStoreState, TAppStoreState>): Promise<void> => {
      commit('refresh');
      await dispatch('getEvent', state.eventId);
    },

    createEvent: async (context: ActionContext<TEventStoreState, TAppStoreState>, payload: TEventRequest): Promise<TEvent> => {
      const { commit, state } = context;

      if (state.eventEntity.isLoading) {
        try {
          return await createEventRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventRequest');
      let data;
      try {
        data = await createEventRequest.load(payload);
        return data;
      } catch (error) {
        commit('eventError', error.data);
        return null;
      } finally {
        commit('event', data);
      }
    },

    editEvent: async (context: ActionContext<TEventStoreState, TAppStoreState>, params: TEditEventParams): Promise<TEvent | boolean> => {
      const { commit, state } = context;

      if (state.eventEntity.isLoading) {
        try {
          return await editEventRequest.promise;
        } catch (error) {
          return null;
        }
      }

      // commit('eventRequest');
      let data;
      try {
        data = await editEventRequest.load(params);
        return data || true; // TODO: WTF?
      } catch (error) {
        commit('eventError', error.data);
        return null;
      }
      // finally {
      //   commit('event', data);
      // }
    },

    getEvent: async (context: ActionContext<TEventStoreState, TAppStoreState>, eventId: number): Promise<TEvent> => {
      const { commit, dispatch, state } = context;

      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
        commit('setEventContactTags', []);
      }

      if (state.eventEntity.data) {
        return state.eventEntity.data;
      }

      if (state.eventEntity.isLoading) {
        try {
          return await getEventRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventRequest');
      let data;
      let isRedirected = false;
      try {
        data = await getEventRequest.load({ eventId });

        // If the event has .event_domain, check it and redirect if it is fine.
        isRedirected = EventHelper.redirectToEventDomain(data);

        if (eventApi.token) {
          await dispatch('getEventContactTags', { eventId });
        }

        return data;
      } catch (error) {
        commit('eventError', error);
        return null;
      } finally {
        if (!isRedirected) {
          commit('event', data);
          await dispatch('getEventSettings', { eventId });
        }
      }
    },

    getEventContactTags: async (context: ActionContext<TEventStoreState, TAppStoreState>): Promise<TContactTag[]> => {
      const { commit, state } = context;
      let data;
      commit('startedContactTagsRequest');
      try {
        data = await getContactTagsRequest.load({ eventId: state.eventId });
        commit('setEventContactTags', data);
        commit('finishedContactTagsRequest');
        return data;
      } catch (error) {
        commit('finishedContactTagsRequest');
      }

      return state.eventContactTags.data;
    },

    createContactTag: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TCreateContactTagRequestParams): Promise<void> => {
      await eventApi.createContactTag(payload);
    },

    removeContactTag: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TDeleteContactTagParams): Promise<void> => {
      await deleteContactTagRequest.load(payload);
    },

    getEventTags: async (context: ActionContext<TEventStoreState, TAppStoreState>, params: { eventId: number }): Promise<TEventTag[]> => {
      const { commit, state } = context;
      let data;
      commit('startedContactTagsRequest');
      try {
        data = await getEventTagsRequest.load({ eventId: (params && params.eventId) || state.eventId }); // TODO: do we need it as a param or using state is ok? use only one
        commit('setEventTags', data);
        commit('finishedEventTagsRequest');
        return data;
      } catch (error) {
        commit('finishedEventTagsRequest');
      }

      return [];
    },

    getEventTypes: async ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): Promise<TEventType[]> => {
      let data;
      commit('startedEventTypesRequest');
      try {
        data = await eventApi.getEventTypes();
        commit('setEventTypes', data);
        commit('finishedEventTypesRequest');
        return data;
      } catch (error) {
        commit('finishedEventTypesRequest');
        commit('setEventTypesRequestError', error);
      }
      return [];
    },

    createEventTag: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TCreateContactTagRequestParams): Promise<void> => {
      await eventApi.createEventTag(payload);
    },

    removeEventTag: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TDeleteContactTagParams): Promise<void> => {
      await deleteEventTagRequest.load(payload);
    },

    getEventSettings: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TGetEventSettingsParams): Promise<void> => {
      const { commit } = context;
      let settings;
      try {
        settings = await eventApi.getEventSettings(payload);
      } catch (error) {
        /* ignore */
        if (error && error.originalError && error.originalError.response && error.originalError.response.status === 404) {
          commit('setEventSettingsPolled', true);
        }
        return;
      }
      commit('setEventSettings', settings);
      commit('setEventSettingsPolled', true);
      commit('setIsStreamingEnabled', !!(settings && settings.layout && settings.layout.is_streaming_enabled === true));
    },

    patchEventSettings: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TPatchEventSettingsParams): Promise<void> => {
      const { commit } = context;
      try {
        await eventApi.patchEventSettings(payload);
      } catch (error) {
        commit('setEventSettingsError', error.data);
        return;
      }

      commit('setEventSettings', payload);
    },

    setObsSettingsDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>,
      obsSettingsDialogConfig: TMeetingRoomConfig): void {
      commit('setObsSettingsDialogConfig', obsSettingsDialogConfig);
    },

    setStreamYardSettingsDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>,
      streamYardSettingsDialogConfig: TMeetingRoomConfig): void {
      commit('setStreamYardSettingsDialogConfig', streamYardSettingsDialogConfig);
    },

    setZoomSettingsDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>,
      zoomSettingsDialogConfig: TMeetingRoomConfig): void {
      commit('setZoomSettingsDialogConfig', zoomSettingsDialogConfig);
    },

    setEmbedCodeDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>,
      embedCodeDialogConfig: TMeetingRoomConfig): void {
      commit('setEmbedCodeDialogConfig', embedCodeDialogConfig);
    },

    setIsBroadcastTimeCheckDialogVisible({ commit }: ActionContext<TEventStoreState, TAppStoreState>,
      isBroadcastTimeCheckDialogVisible: boolean): void {
      commit('setIsBroadcastTimeCheckDialogVisible', isBroadcastTimeCheckDialogVisible);
    },

    setIsChatRoomTimeCheckDialogVisible({ commit }: ActionContext<TEventStoreState, TAppStoreState>,
      isChatRoomTimeCheckDialogVisible: boolean): void {
      commit('setIsChatRoomTimeCheckDialogVisible', isChatRoomTimeCheckDialogVisible);
    },

    registerUser: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TRegisterUserParams): Promise<void> => {
      let result: any;
      try {
        result = await eventApi.registerUser(payload);
      } catch (error) {
        /* ignore */
      }
      return result;
    },

    eventRestrictions: async (context: ActionContext<TEventStoreState, TAppStoreState>,
      payload: TEventRestrictions): Promise<void> => {
      let result: any;
      const { commit } = context;
      try {
        result = await eventApi.eventRestrictions(payload);
        commit('eventRestrictions', result);
      } catch (error) {
        /* ignore */
      }
      return result;
    },

    setPrivateEventDialogVisibility({commit}: ActionContext<TEventStoreState, TAppStoreState>, isVisible: boolean): void {
      commit('setPrivateEventDialogVisibility', isVisible);
    },

    setSelectedTimezone({commit}: ActionContext<TEventStoreState, TAppStoreState>, selectedTimezone: string ): void {
      commit('setSelectedTimezone', selectedTimezone);
    },

    activateTicketCode: async (context: ActionContext<TEventStoreState, TAppStoreState>, params: TActivateTicketCodeParams): Promise<boolean> => {
      let result: boolean;
      try {
        result = await eventApi.activateTicketCode(params);
      } catch {
        result = false;
      }
      return result;
    },

    updateMapImage: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>, newVal: string): void => {
      commit('updateMapImage', newVal);
    },

    setEventSettingsPolled: ({ commit}: ActionContext<TEventStoreState, TAppStoreState>, newVal: boolean): void => {
      commit('setEventSettingsPolled', newVal);
    },

    showBecomingAContactConfirm: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('setBecomingAContactPopupVisibility', true);
    },

    hideBecomingAContactConfirm: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('setBecomingAContactPopupVisibility', false);
    },

    confirmBecomingAContact: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('setBecomingAContactPopupAgreed', true);
      commit('setBecomingAContactPopupVisibility', false);
    },

    cancelBecomingAContact: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('setBecomingAContactPopupAgreed', false);
      commit('setBecomingAContactPopupVisibility', false);
    },

    setIsHelpCrunchReady: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>, isReady: boolean): void => {
      commit('setIsHelpCrunchReady', isReady);
    },

    addEventToFavorites: async (context: ActionContext<TEventStoreState, TAppStoreState>, payload: { eventId: number }): Promise<void> => {
      try {
        await eventApi.addEventToFavorites(payload);
      } catch { /* ignore */ }
    },

    removeEventFromFavorites: async (context: ActionContext<TEventStoreState, TAppStoreState>, payload: { eventId: number }): Promise<void> => {
      try {
        await eventApi.deleteEventFromFavorites(payload);
      } catch { /* ignore */ }
    },
  },
  mutations: {

    refresh(state: TEventStoreState): void {
      state.eventEntity.data = null;
      state.eventEntity.isLoading = false;
      state.eventEntity.error = null;
      state.eventSettings.data = null;
      state.eventSettings.isLoading = false;
      state.isEventSettingsPolled = false;
      state.eventSettings.error = null;
      state.obsSettingsDialogConfig = null;
      state.streamYardSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
      state.isConfirmBecomingAContactPopupVisible = false;
      state.hasAgreedToBecomeAContact = false;
      state.isHelpCrunchReady = false;
    },

    setEventId(state: TEventStoreState, eventId: number): void {
      if (!eventId || eventId !== state.eventId) {
        getEventRequest.cancel('Canceled by store');
      }
      state.eventId = eventId || null;
      state.eventEntity.data = null;
      state.eventEntity.isLoading = false;
      state.eventEntity.error = null;
      state.obsSettingsDialogConfig = null;
      state.streamYardSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    eventRequest(state: TEventStoreState): void {
      state.eventEntity.data = null;
      state.eventEntity.isLoading = true;
      state.eventEntity.error = null;
    },

    eventError(state: TEventStoreState, error: ApiErrorResponseData): void {
      state.eventEntity.error = error;
    },
    setEventSettingsError(state: TEventStoreState, error: ApiErrorResponseData): void {
      state.eventSettings.error = error;
    },

    event(state: TEventStoreState, event: TEvent): void {
      if (event) { // TODO: do we need custom date fields?
        // event.dateStartMoment = (event.date_start && moment(event.date_start)) || null;
        // event.dateEndMoment = (event.date_end && moment(event.date_end)) || null;
      }
      state.eventEntity.data = event || null;
      state.eventEntity.isLoading = false;
      state.selectedTimezone = momentTimezone.tz.guess();
    },

    setEventSettings: (state: TEventStoreState, settings: TEventSettings): void => {
      state.eventSettings = {
        data: settings,
        isLoading: false,
        error: null
      };
    },

    setIsStreamingEnabled: (state: TEventStoreState, isStreamingEnabled: boolean): void => {
      state.isStreamingEnabled = !!isStreamingEnabled;
    },

    clearEventSettings: (state: TEventStoreState): void => {
      state.eventSettings = {
        data: {
          event_id: null,
          layout: null
        },
        isLoading: false,
        error: null
      };
    },

    setEventSettingsPolled: (state: TEventStoreState, value: boolean): void => {
      state.isEventSettingsPolled = value;
    },

    setObsSettingsDialogConfig: (state: TEventStoreState, obsSettingsDialogConfig: TMeetingRoomConfig): void => {
      state.obsSettingsDialogConfig = obsSettingsDialogConfig;
      state.streamYardSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setStreamYardSettingsDialogConfig: (state: TEventStoreState,
      streamYardSettingsDialogConfig: TMeetingRoomConfig): void => {
      state.streamYardSettingsDialogConfig = streamYardSettingsDialogConfig;
      state.obsSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setZoomSettingsDialogConfig: (state: TEventStoreState, zoomSettingsDialogConfig: TMeetingRoomConfig): void => {
      state.obsSettingsDialogConfig = null;
      state.streamYardSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = zoomSettingsDialogConfig;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setEmbedCodeDialogConfig: (state: TEventStoreState, embedCodeDialogConfig: TMeetingRoomConfig): void => {
      state.obsSettingsDialogConfig = null;
      state.streamYardSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = embedCodeDialogConfig;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setIsBroadcastTimeCheckDialogVisible: (state: TEventStoreState,
      isBroadcastTimeCheckDialogVisible: boolean): void => {
      state.obsSettingsDialogConfig = null;
      state.streamYardSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = isBroadcastTimeCheckDialogVisible;
    },

    setIsChatRoomTimeCheckDialogVisible: (state: TEventStoreState, isChatRoomTimeCheckDialogVisible: boolean): void => {
      state.isChatRoomTimeCheckDialogVisible = isChatRoomTimeCheckDialogVisible;
    },

    setEventContactTags: (state: TEventStoreState, data: TContactTag[]): void => {
      state.eventContactTags.data = data.reverse();
    },

    startedContactTagsRequest: (state: TEventStoreState): void => {
      state.eventContactTags.isLoading = true;
    },

    finishedContactTagsRequest: (state: TEventStoreState): void => {
      state.eventContactTags.isLoading = false;
    },

    setEventTags: (state: TEventStoreState, data: TEventTag[]): void => {
      state.eventTags.data = data;
    },

    startedEventTagsRequest: (state: TEventStoreState): void => {
      state.eventTags.isLoading = true;
    },

    finishedEventTagsRequest: (state: TEventStoreState): void => {
      state.eventTags.isLoading = false;
    },

    setEventTypes: (state: TEventStoreState, data: TEventType[]): void => {
      state.eventTypes.data = data;
    },

    startedEventTypesRequest: (state: TEventStoreState): void => {
      state.eventTypes.isLoading = true;
      state.eventTypes.error = null;
    },

    finishedEventTypesRequest: (state: TEventStoreState): void => {
      state.eventTypes.isLoading = false;
    },

    setEventTypesRequestError: (state: TEventStoreState, payload: ApiErrorResponseData): void => {
      state.eventTypes.error = payload;
    },

    eventRestrictions: (state: TEventStoreState, eventRestrictions: TEventRestrictions): void => {
      state.eventRestrictions = eventRestrictions;
    },

    setPrivateEventDialogVisibility: (state: TEventStoreState, isVisible: boolean): void => {
      state.isPrivateEventDialogVisible = isVisible;
    },

    setSelectedTimezone: (state: TEventStoreState, selectedTimezone: string): void => {
      state.selectedTimezone = selectedTimezone;
    },

    updateMapImage: (state: TEventStoreState, newMapImageUrl: string): void => {
      if (!state.eventEntity.data) {
        return;
      }
      state.eventEntity.data.map_image = newMapImageUrl;
    },

    setBecomingAContactPopupVisibility: (state: TEventStoreState, isVisible: boolean): void => {
      state.isConfirmBecomingAContactPopupVisible = isVisible;
    },

    setBecomingAContactPopupAgreed: (state: TEventStoreState, hasAgreed: boolean): void => {
      state.hasAgreedToBecomeAContact = hasAgreed;
    },

    setIsHelpCrunchReady: (state: TEventStoreState, isReady: boolean): void => {
      state.isHelpCrunchReady = isReady;
    },

  },
};

export default _eventStore;
